document.addEventListener("DOMContentLoaded", function() {
    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",

        callback_enter: function(el) {
            el.setAttribute('src', '/assets/frontend/img/loading.gif');
        },

        callback_loaded: function(el) {
            el.classList.remove('loading');
            el.classList.add('loaded');
        },

        callback_error: function(el) {
            el.setAttribute('src', '/assets/frontend/img/error.svg');
        }
    });
});